<template>
  <div class="popup-container" @click.stop>
    <div class="overlay">
      <div class="close" @click="$store.dispatch('showPopup', { clear: true })">
        &times;
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SvPopup",
  props: ["visible"]
};
</script>

<style scoped>
.popup-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  z-index: 5;
}
.overlay {
  width: 320px;
  height: 575px;
  background: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.close {
  position: absolute;
  top: 11px;
  right: 15px;
  line-height: 20px;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
}
</style>
