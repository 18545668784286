<template>
  <popupContainer class="upload-popup-container">
    <div class="scroll" v-if="filesList.length || uploadingImages.length">
      <div class="upload-item" v-for="(f, idx) of filesList" :key="idx">
        <div class="img-container">
          <div class="lds-dual-ring"></div>
          <img :src="f" @error="onErrorImage" @load="onLoadImage" />
        </div>
        <div class="delete-file" @click="onDelete(f)">delete</div>
      </div>
      <div
        class="upload-item"
        v-for="f of uploadingImages"
        :key="f.src"
        v-show="uploadingImages.length"
      >
        <div class="img-container">
          <div class="lds-dual-ring-always"></div>
          <div class="proggress" :style="{ width: f.progress + '%' }"></div>
          <img :src="f.src" @error="onErrorImage" @load="onLoadImage" />
        </div>
        <div class="delete-file" @click="abortUploading(f)">
          delete
        </div>
      </div>
    </div>
    <label @click.stop class="upload-btn-container">
      <input hidden :accept="fileFromats" type="file" @input="onUlpoad" />
      <div>upload files</div>
    </label>
    <div class="notification" :class="{ shown: isShownNotification }">
      {{ errorMsg }}
    </div>
  </popupContainer>
</template>

<script>
import popupContainer from "@/components/PopupContainer";
import { INSERT_USER_ATTACHMENT, DELETE_USER_ATTACHMENT } from "@/gql";
import uploadFileFormats from "@/store/mocks/uploadFileFormats";
import { updateRespond } from "@/utils/clickAction";
import { mapGetters } from "vuex";
export default {
  name: "SvPopupUpload",
  props: ["popupData"],
  components: {
    popupContainer,
  },
  computed: {
    ...mapGetters([
      "activeStep",
      "respond",
      "respondId",
      "owner",
      "step",
      "interactionId",
      "ownerId",
    ]),
    widget() {
      return this.activeStep?.objects?.[this.popupData.widgetIdx];
    },
    fileSizeLimit() {
      return (this.widget?.configs?.sizeLimit || 1) * 1024 * 1024;
    },
    fileFromats() {
      const list = this.widget?.configs?.allowedFileType;
      return list.reduce((acc, f) => {
        acc += uploadFileFormats[f].join(", ");
        return acc;
      }, "");
    },
  },
  data() {
    return {
      defaultImage: require("@/assets/img/file.svg"),
      filesList: [],
      errorMsg: "",
      hidePreloader: [],
      uploadingImages: [],
      timeoutId: null,
      isShownNotification: false,
    };
  },
  mounted() {
    const respondItem = this.respond?.[this.step]?.[this.popupData.widgetIdx];
    if (Array.isArray(respondItem?.value)) this.filesList = respondItem.value;
    else this.filesList = [];
  },
  methods: {
    showNotification(msg) {
      this.isShownNotification = true;
      this.errorMsg = msg;
      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.isShownNotification = false;
      }, 5000);
    },
    abortUploading(img) {
      img.xhr.abort();
      this.uploadingImages = this.uploadingImages.filter((i) => i !== img);
    },
    async onUlpoad(e) {
      const file = e.target.files[0];
      if (!file) return;
      const fileFromatsList = this.fileFromats.split(", ");
      let accepted = false;
      if (fileFromatsList.length) {
        for (let i of fileFromatsList)
          if (file.name.endsWith(i)) accepted = true;
      } else accepted = true;
      if (!accepted) {
        this.showNotification("wrong file format!");
        return;
      }
      if (file.size > this.fileSizeLimit) {
        this.showNotification("file is too big!");
        return;
      }
      const xhr = new XMLHttpRequest();
      const blobUrl = URL.createObjectURL(file);
      const imageData = { src: blobUrl, xhr, progress: 0 };
      this.uploadingImages.push(imageData);
      try {
        const fileUrl = await this.uploadFile(file, xhr, imageData);
        await this.$apollo.mutate({
          mutation: INSERT_USER_ATTACHMENT,
          variables: {
            content: fileUrl,
            size: file.size,
            name: file.name,
            myInteractionId: this.interactionId,
            respondId: this.respondId,
            userId: this.ownerId,
          },
        });
        if (!fileUrl) return;
        this.filesList.push(fileUrl);
        await this.$store.dispatch("updateRespond", {
          data: {
            // this.filesList,
            widgetType: this.popupData.widgetType,
            widgetName: this.popupData.widgetName,
            actionType: this.popupData.actionType,
            actionData: this.filesList,
          },
          step: this.step,
          idx: this.popupData.widgetIdx,
        });
        this.uploadingImages = this.uploadingImages.filter(
          (i) => i.src !== blobUrl
        );
        URL.revokeObjectURL(blobUrl);
        updateRespond();
      } catch (error) {
        this.uploadingImages = this.uploadingImages.filter(
          (i) => i.src !== blobUrl
        );
        URL.revokeObjectURL(blobUrl);
        console.log(error);
      }
    },
    async uploadFile(file, xhr, imageData) {
      const formDataImg = new FormData();
      const fileName =
        Date.now().toString(36) +
        Math.random()
          .toString(36)
          .slice(2);
      file = new File([file], fileName, { type: file.type });
      formDataImg.append("direction", "my_interactions");
      formDataImg.append("user_email", this.owner + "/files");
      formDataImg.append("upload", file);
      xhr.responseType = "json";
      xhr.upload.onprogress = function(event) {
        imageData.progress = (100 / event.total) * event.loaded;
      };
      xhr.open("POST", `${process.env.VUE_APP_AUTH_SERVER}/upload`);

      xhr.send(formDataImg);
      const res = await new Promise((resolve) => {
        xhr.onload = function() {
          resolve(xhr.response);
        };
      });
      return res?.files?.[0]?.location;
    },
    onErrorImage(e) {
      const img = e.target;
      img.src = this.defaultImage;
    },
    onLoadImage(e) {
      const loader = e.target.parentElement.querySelector(".lds-dual-ring");
      if (loader) loader.style.opacity = "0";
      // loader.style.zIndex = "-1";
    },
    async onDelete(url) {
      try {
        await this.deleteFile(url);
        await this.$apollo.mutate({
          mutation: DELETE_USER_ATTACHMENT,
          variables: { content: url },
        });
        this.filesList = this.filesList.filter((i) => i !== url);
        this.$store.dispatch("updateRespond", {
          data: {
            // this.filesList,
            widgetType: this.popupData.widgetType,
            widgetName: this.popupData.widgetName,
            actionType: this.popupData.actionType,
            actionData: this.filesList,
          },
          step: this.step,
          idx: this.popupData.widgetIdx,
          type: "uploads",
        });
        updateRespond();
      } catch (error) {
        console.log(error);
      }
    },
    deleteFile(url) {
      return fetch(`${process.env.VUE_APP_AUTH_SERVER}/delete`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          key: url.split("com/")[1].replace(/%40/g, "@"),
        }),
      });
    },
  },
};
</script>

<style>
.notification {
  position: fixed;
  top: 0px;
  right: 20px;
  z-index: 0;
  padding: 14px 20px;
  background: black;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 12px;
  box-shadow: black 2px 2px 5px;
  opacity: 0;
  transition: all 0.3s;
}
.notification.shown {
  top: 20px;
  opacity: 1;
  z-index: 10;
}
.proggress {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  height: 5px;
  background: #ffe000;
}
.upload-popup-container .overlay {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 5px;
  box-sizing: border-box;
}
.scroll {
  max-height: 460px;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
}
.upload-btn-container {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 24px;
}
.upload-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  margin-bottom: 15px;
}
.upload-item img {
  max-height: 120px;
  max-width: 120px;
  height: 120px;
  width: 120px;
  object-fit: contain;
  padding: 5px;
}
.delete-file {
  margin: 0 15px;
  font-weight: 5 00;
  font-size: 16px;
  cursor: pointer;
}
.img-container {
  margin: auto;
  display: flex;
  align-items: center;
  max-width: 120px;
  max-height: 120px;
  position: relative;
}
.lds-dual-ring,
.lds-dual-ring-always {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #00000033;
  transition: opacity 0.2s;
}
.lds-dual-ring:after,
.lds-dual-ring-always:after {
  content: " ";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
