export default {
  docs: [
    ".pdf",
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
    ".odt",
    ".ods",
    ".odp",
  ],
  images: [
    ".png",
    ".jpg",
    ".jpeg",
    ".tff",
    ".gif",
    ".webp",
    ".bmp",
    ".psd",
    ".svg",
    ".ai",
    ".eps",
  ],
  videos: [".mp4", ".mov", ".mkv", ".m4v", ".avi", ".flv"],
  audio: [".mp3", ".ogg", ".aac", ".wma", ".flac", ".wav"],
};
